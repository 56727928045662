import React, { Fragment } from 'react';
import uniqid from 'uniqid';

import FacebookIcon from '../../images/icons/facebook-icon.svg';
import LinkedInIcon from '../../images/icons/linkedin-icon.svg';
import TwitterIcon from '../../images/icons/twitter-icon.svg';
import InstagramIcon from '../../images/icons/instagram-icon.svg';

const SocialMedia = ({ className, items, icons, click, buttons }) => {
  return (
    <ul className={`social-media ${className ? ` ${className}` : ''}`}>
      {items.map(({ network, url }) => {
        const { icon: Icon } = icons.find(item => item.key === network);
        const body = (
          <Fragment>
            <span className="social-media__text">{network}</span>
            <Icon className="social-media__icon" />
          </Fragment>
        );

        return (
          <li className="social-media__item" key={uniqid()}>
            {buttons ? (
              <button className="social-media__link" type="button" onClick={e => click(e, url)}>
                {body}
              </button>
            ) : (
              <a
                className="social-media__link"
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                onClick={e => click(e, network, url)}
              >
                {body}
              </a>
            )}
          </li>
        );
      })}
    </ul>
  );
};

SocialMedia.defaultProps = {
  icons: [
    {
      key: 'Twitter',
      icon: TwitterIcon,
    },
    {
      key: 'LinkedIn',
      icon: LinkedInIcon,
    },
    {
      key: 'Facebook',
      icon: FacebookIcon,
    },
    {
      key: 'Instagram',
      icon: InstagramIcon,
    },
  ],
  click: () => {},
};

export default SocialMedia;
