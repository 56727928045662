/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import Header from './organisms/main-header';
import Footer from './organisms/main-footer';

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
        wordpressAcfOptions {
          options {
            copyright
            privacyTermsLinkText
            privacyTermsLinkUrl
            privacyText
            privacyUrl
            socialMediaLinks {
              network
              url
            }
          }
        }
        primaryNavigation: wordpressWpApiMenusMenusItems(wordpress_id: { eq: 2 }) {
          items {
            wordpress_id
            title
            url
            children: wordpress_children {
              wordpress_id
              title
              url
            }
          }
        }
        footerNavigation: wordpressWpApiMenusMenusItems(wordpress_id: { eq: 3 }) {
          items {
            wordpress_id
            title
            url
            children: wordpress_children {
              wordpress_id
              title
              url
            }
          }
        }
      }
    `}
    render={data => (
      <div className="wrapper">
        <Header siteTitle={data.site.siteMetadata.title} navigationItems={data.primaryNavigation.items} />
        <Helmet>
          <link href="https://fonts.googleapis.com/css?family=EB+Garamond:400,400i" rel="stylesheet" />
        </Helmet>
        <Helmet>
          <link href="https://fonts.googleapis.com/css?family=Courgette" rel="stylesheet" />
        </Helmet>
        <main className="wrapper__main">{children}</main>
        <Footer
          navigationItems={data.footerNavigation.items}
          copyright={data.wordpressAcfOptions.options.copyright}
          privacyTermsLinkText={data.wordpressAcfOptions.options.privacyTermsLinkText}
          privacyTermsLinkUrl={data.wordpressAcfOptions.options.privacyTermsLinkUrl}
          privacyText={data.wordpressAcfOptions.options.privacyText}
          privacyUrl={data.wordpressAcfOptions.options.privacyUrl}
          socialMediaLinks={data.wordpressAcfOptions.options.socialMediaLinks}
        />
      </div>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
