import React, { Component, Fragment } from 'react';
import { Link } from 'gatsby';

class Navigation extends Component {
  getChildListClass(level, cssClass) {
    return `${this.props.cssClass}-child-list${cssClass ? ` ${cssClass}` : ''}`;
  }

  getItemClass(level, hasChildren) {
    let cssClass = `${this.props.cssClass}-item`;

    if (level > 0) {
      cssClass = `${this.props.cssClass}-sub-item`;
    }

    if (hasChildren) {
      cssClass = `${cssClass} ${cssClass}--has-children`;
    }

    return cssClass;
  }

  getLinkClass(level) {
    if (level > 0) {
      return `${this.props.cssClass}-sub-link`;
    }

    return `${this.props.cssClass}-link`;
  }

  getLinkClassActive(level) {
    if (level > 0) {
      return `${this.props.cssClass}-sub-link--active`;
    }

    return `${this.props.cssClass}-link--active`;
  }

  renderList(cssClass, items, level) {
    return <ul className={cssClass}>{this.renderItems(items, level)}</ul>;
  }

  renderLink = (item, level, partiallyActive = true) => {
    return this.props.renderLink ? (
      this.props.renderLink(item, this.getLinkClass(level))
    ) : (
      <Link
        className={this.getLinkClass(level)}
        to={item.url}
        activeClassName={this.getLinkClassActive(level)}
        partiallyActive={partiallyActive}
      >
        {item.title}
      </Link>
    );
  };

  renderChildren = (children, level, cssClass) => {
    if (children && children.length > 0) {
      return this.renderList(this.getChildListClass(level, cssClass), children, level + 1);
    }

    return null;
  };

  renderItem(item, level) {
    return (
      <Fragment>
        {this.renderLink(item, level)}
        {this.renderChildren(item.children, level)}
      </Fragment>
    );
  }

  renderItems(items, level) {
    return items.map((item, index) => {
      let renderItem;

      if (this.props.renderItem) {
        renderItem = this.props.renderItem(item, level, this.renderLink, this.renderChildren);

        if (!renderItem) {
          renderItem = this.renderItem(item, level);
        }
      } else {
        renderItem = this.renderItem(item, level);
      }
      return (
        <li key={index} className={this.getItemClass(level, !!item.children)}>
          {renderItem}
        </li>
      );
    });
  }

  render() {
    return this.renderList(this.props.cssClass, this.props.items, 0);
  }
}

Navigation.defaultProps = {
  items: [
    {
      title: 'Test',
      slug: 'test',
    },
    {
      title: 'Another test',
      slug: 'another-test',
    },
  ],
};

export default Navigation;
