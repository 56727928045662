import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import Navigation from './navigation';
import ShowHide from '../atoms/show-hide';
import Arrow from '../../images/icons/arrow.svg';

const openText = 'Show children';
const closeText = 'Hide children';

const PrimaryNavigation = ({ items, open }) => (
  <nav className={`primary-navigation ${open ? ' primary-navigation--open' : ''}`}>
    <div className="primary-navigation__inner">
      <Navigation
        cssClass="primary-navigation__list"
        items={items}
        renderItem={(item, level, renderLink, renderChildren) => {
          if (item.children && item.children.length > 0) {
            return (
              <ShowHide
                renderTrigger={(open, toggleShowHide) => {
                  const triggerText = !open ? openText : closeText;

                  return (
                    <Fragment>
                      {renderLink(item, level, true)}
                      <Arrow className="primary-navigation__has-children-icon" />
                      <button
                        type="button"
                        className={`primary-navigation__toggle-children ${
                          open ? ' primary-navigation__toggle-children--active' : ''
                        }`}
                        onClick={toggleShowHide}
                      >
                        <Arrow className="primary-navigation__toggle-children-icon" />
                        <span className="primary-navigation__toggle-children-text">{triggerText}</span>
                      </button>
                    </Fragment>
                  );
                }}
                renderContent={open => {
                  let cssClass = open ? 'primary-navigation__list-child-list--active' : null;

                  return renderChildren(item.children, level, cssClass);
                }}
              />
            );
          }

          return null;
        }}
      />
    </div>
  </nav>
);

export default PrimaryNavigation;
