import React, { Component, Fragment } from 'react';

class ShowHide extends Component {
  state = {
    open: false,
  };

  toggleShowHide = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  renderTrigger(open) {
    if (this.props.renderTrigger) {
      return this.props.renderTrigger(open, this.toggleShowHide);
    }

    return (
      <button
        type="button"
        className={`show-hide-trigger ${open ? 'show-hide-trigger--active' : ''}`}
        onClick={this.toggleShowHide}
      >
        {this.props.renderTriggerContent(open)}
      </button>
    );
  }

  renderContent(open) {
    if (this.props.renderContent) {
      return this.props.renderContent(open);
    }

    const body = this.props.renderBody ? this.props.renderBody() : this.props.children;

    return <div className={`show-hide-content ${open ? 'show-hide-content--active' : ''}`}>{body}</div>;
  }

  render() {
    const { open } = this.state;

    return (
      <Fragment>
        {this.renderTrigger(open)}
        {this.renderContent(open)}
      </Fragment>
    );
  }
}

export default ShowHide;
