import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import ReactStringReplace from 'react-string-replace';
import Navigation from '../molecules/navigation';
import SocialMedia from '../atoms/social-media';

const MainFooter = ({
  copyright,
  privacyTermsLinkUrl,
  privacyTermsLinkText,
  privacyText,
  privacyUrl,
  navigationItems,
  socialMediaLinks,
}) => (
  <footer className="main-footer">
    <div className="main-footer__inner">
      <div className="main-footer__navigation">
        <Navigation cssClass="main-footer__navigation-list" items={navigationItems} />
      </div>
      <div className="main-footer__secondary">
        <SocialMedia className="main-footer__social-media" items={socialMediaLinks} />
        <div
          className="main-footer__copyright"
          dangerouslySetInnerHTML={{
            __html: ReactStringReplace(copyright, '[year]', () => new Date().getFullYear()).join(''),
          }}
        />
        {privacyTermsLinkText && privacyTermsLinkUrl && (
          <Link className="main-footer__privacy-terms" to={privacyTermsLinkUrl}>
            {privacyTermsLinkText}
          </Link>
        )}
        {privacyText && privacyUrl && (
          <Link className="main-footer__privacy-terms" to={privacyUrl}>
            {privacyText}
          </Link>
        )}
      </div>
    </div>
  </footer>
);

MainFooter.propTypes = {
  siteTitle: PropTypes.string,
};

MainFooter.defaultProps = {
  siteTitle: ``,
};

export default MainFooter;
