import { Link, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import Branding from '../../images/icons/branding.svg';
import PrimaryNavigation from '../molecules/primary-navigation';
import SearchIcon from '../../images/icons/search.svg';

class MainHeader extends React.Component {
  static openText = 'Show navigation';

  static closeText = 'Close navigation';

  navigationEl = null;

  state = {
    open: false,
  };

  componentDidMount() {
    this.navigationEl = document.querySelector('.primary-navigation');
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  lockSroll = () => {
    disableBodyScroll(this.navigationEl);
  };

  unlockScroll = () => {
    enableBodyScroll(this.navigationEl);
  };

  toggleNavigation = () => {
    if (!this.state.open) {
      this.lockSroll();

      this.setState({
        open: true,
      });
    } else {
      this.unlockScroll();

      this.setState({
        open: false,
      });
    }
  };

  render() {
    const { siteTitle, navigationItems } = this.props;
    const { open } = this.state;
    const buttonText = open ? MainHeader.openText : MainHeader.closeText;

    return (
      <header className="main-header">
        <div className="main-header__inner">
          <div className="main-header__content">
            <Link className="main-header__branding" to="/">
              <Branding className="main-header__branding-image" />
              <span className="main-header__branding-text">{siteTitle}</span>
            </Link>
            <div className="main-header__controls">
              <button
                className={`main-header__toggle-navigation hamburger hamburger--squeeze${open ? ' is-active' : ''}`}
                type="button"
                aria-label="Menu"
                aria-controls="navigation"
                onClick={this.toggleNavigation}
              >
                <span className="main-header__toggle-navigation-inner hamburger-box">
                  <span className="hamburger-inner" />
                  <span className="main-header__toggle-navigation-text">{buttonText}</span>
                </span>
              </button>
            </div>
          </div>
          <PrimaryNavigation items={navigationItems} open={open} ref={this.navigationEl} />
          <Link className="main-header__search" to="/search">
            <span className="main-header__search-text">Search</span>
            <SearchIcon className="main-header__search-icon" />
          </Link>
        </div>
      </header>
    );
  }
}

MainHeader.propTypes = {
  siteTitle: PropTypes.string,
};

MainHeader.defaultProps = {
  siteTitle: ``,
};

export default MainHeader;
